import { useEffect } from "react";

export const LottieVideo = ({ src }) => {
  useEffect(() => {
    const player = document.querySelector("lottie-player");
    player.load(src);
  }, [src]);

  return (
    <lottie-player
      src={src}
      background="transparent"
      speed="1"
      loop
      autoplay
      class="animation"
    ></lottie-player>
  );
};
