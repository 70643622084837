import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { Header } from "./Header";
import { Recording } from "./Recording";
import { Footer } from "./Footer";
import { Facts } from "./Facts";
import { Result } from "./Result";
import { ResultBreakDown } from "./ResultBreakdown";
import { AboutTechnology } from "./AboutTechnology";
import { Rules } from "./Rules";
import { HowToFirst } from "./HowTo/First";
import { HowToSecond } from "./HowTo/Second";
import { HowToThird } from "./HowTo/Third";
import { Error } from "./Error";

const useStyles = createUseStyles({
  container: {
    height: "470px",
    width: "636px",
    padding: "0 32px",
    position: (pageTitle) => (pageTitle === "recording" ? "fixed" : "unset"),
    top: 0,

    "@media screen and (max-width: 560px)": {
      width: "calc(100% - 32px)",
      height: "calc(100% - 140px)",
      padding: "0 16px",
    },

    "@media screen and (max-width: 385px)": {
      height: "calc(100% - 60px)",
    },
  },
  video: {
    position: "fixed",
    right: "left",
    top: 0,
    display: (pageTitle) => (pageTitle === "recording" ? "block" : "none"),

    "@media screen and (max-width: 560px)": {
      objectFit: "cover",
      width: "100%",
    },
  },
  globalContainer: {
    height: "600px",
    backgroundImage: "url(/images/background.jpeg)",

    "@media screen and (max-width: 560px)": {
      minHeight: "900px",
    },
  },
});

export const ContentContainer = ({ setViewStart }) => {
  const [pageTitle, setPageTitle] = useState("aboutTechnology");
  const [recordingId, setRecordingId] = useState();
  const [result, setResult] = useState();
  const [error, setError] = useState();

  const { t } = useTranslation();

  const errors = {
    loud: t("errorLoud"),
    quite: t("errorQuite"),
  };

  useEffect(() => {
    if (recordingId) {
      setPageTitle("facts");
    }
  }, [recordingId]);

  useEffect(() => {
    if (error) {
      setPageTitle("error");
    }
  }, [error]);

  const classes = useStyles(pageTitle);

  const content = {
    aboutTechnology: {
      component: () => (
        <AboutTechnology setPage={setPageTitle} setViewStart={setViewStart} />
      ),
    },
    rules: {
      component: () => <Rules setPage={setPageTitle} />,
    },
    howTo1: {
      component: () => <HowToFirst setPage={setPageTitle} />,
    },
    howTo2: {
      component: () => <HowToSecond setPage={setPageTitle} />,
    },
    howTo3: {
      component: () => <HowToThird setPage={setPageTitle} />,
    },
    recording: {
      component: () => (
        <Recording
          setRecordingId={setRecordingId}
          setPage={setPageTitle}
          setError={setError}
        />
      ),
    },
    error: {
      component: () => (
        <Error
          text={errors[error]}
          setPage={setPageTitle}
          setViewStart={setViewStart}
        />
      ),
    },
    facts: {
      component: () => (
        <Facts
          recordId={recordingId}
          setViewStart={setViewStart}
          setResult={setResult}
          setPageTitle={setPageTitle}
        />
      ),
    },
    result: {
      component: () => (
        <Result
          result={result}
          setPageTitle={setPageTitle}
          setViewStart={setViewStart}
        />
      ),
    },
    resultBD: {
      component: () => <ResultBreakDown setPageTitle={setPageTitle} />,
    },
  };

  const Content = content[pageTitle].component;

  // const width = window.innerWidth;

  return (
    <div className={classes.globalContainer}>
      {/* <video
        className={classes.video}
        loop
        id="myVideo"
        data-inline-media=""
        muted=""
        playsInline=""
        autoPlay={true}
        aria-hidden="true"
        preload="none"
        data-load-timeout="3000"
        data-inline-media-plugins="AnimLoad, FeatureObserver, LoadTimeout, VatPathWithExtensions"
        data-inline-media-disabled-when="reduce-motion, no-enhance-page-xp-media"
        data-wf-ignore="true"
        data-object-fit="cover"
      >
        <source
          src={width > 560 ? "video/bg.mp4" : "video/bg_mobile.mp4"}
          type="video/mp4"
        />
      </video> */}
      <Header />
      <div className={classes.container}>
        <Content />
        <Footer variant="black" />
      </div>
    </div>
  );
};
