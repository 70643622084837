import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  footer: {
    width: "600px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: (props) => (props.variant === "white" ? "#FCFCFD" : "#344054"),
    position: (props) => (props.variant === "white" ? "absolute" : "relative"),
    bottom: (props) => (props.variant === "white" ? 0 : "unset"),
    padding: "12px 32px",
    fontSize: "12px",

    "@media screen and (max-width: 560px)": {
      width: "calc(100% - 24px)",
      flexDirection: "column",
      padding: "12px",
      position: (props) =>
        props.variant === "white" ? "absolute" : "relative",
      top: (props) => (props.variant === "white" ? "unset" : "20px"),

      "& p": {
        width: "100%",
        textAlign: "center",
      },
    },
  },
  link: {
    color: (props) => (props.variant === "white" ? "#FCFCFD" : "#344054"),
    textDecoration: "none",
    marginRight: "16px",
  },
});

export const Footer = (props) => {
  const classes = useStyles(props);

  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <div>
        <a
          href="https://vividmind.health/pl/regulamin"
          className={classes.link}
          target="_blank"
          rel="noreferrer"
        >
          {t("homepageRegulamin")}
        </a>
        <a
          href="https://vividmind.health/pl/polityka-prywatnosci"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          {t("homepagePrivacyPolicy")}
        </a>
      </div>
      <p>{t("footerCopyright")}</p>
      <p>v 1.0.1</p>
    </footer>
  );
};
