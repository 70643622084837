import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",

    "@media screen and (max-width: 560px)": {
      flexDirection: "column",
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    width: "45%",

    "@media screen and (max-width: 560px)": {
      width: "100%",
      order: 2,
    },

    "& h4": {
      fontSize: "20px",
      fontWeight: 700,
      marginBottom: 0,
    },

    "& p": {
      lineHeight: "24px",
    },
  },
  imageContainer: {
    width: "310px",
    height: "328px",
    borderRadius: "20px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media screen and (max-width: 560px)": {
      width: "100%",
      height: "362px",
      order: 1,
    },
  },
});

export const HowToContent = ({ title, text, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
      <div className={classes.imageContainer}>{children}</div>
    </div>
  );
};
