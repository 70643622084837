import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  button: {
    border: "none",
    color: "#076459",
    fontSize: "16px",
    backgroundColor: "transparent",
    height: "20px",
    cursor: "pointer",
    fontWeight: 700,

    "@media screen and (max-width: 560px)": {
      fontSize: "14px",
    },

    "& a": {
      textDecoration: "none",
      color: "#076459",
    },

    "&:hover": {
      color: "#0B8D7E",
    },
  },
});

export const TextButton = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <button {...rest} className={classes.button}>
      {children}
    </button>
  );
};
