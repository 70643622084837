import { createUseStyles } from "react-jss";
import { LottieVideo } from "./LottieVideo";

const useStyles = createUseStyles({
  circle: {
    position: "relative",
  },
  childrenContainer: {
    position: "absolute",
    width: "100px",
    height: "100px",
    bottom: "calc(50% - 50px)",
    right: "calc(50% - 50px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const Circle = ({ children, animation }) => {
  const classes = useStyles();

  return (
    <div className={classes.circle}>
      <LottieVideo src={animation} />
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
};
