import { useState, useEffect } from "react";
import { useCommonStyles } from "../../styles";

export const ThreeCountdown = () => {
  const commonStyles = useCommonStyles();
  const [currentNumber, setCurrentNumber] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNumber((curr) => curr - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return <p className={commonStyles.countdownNumber}>{currentNumber}</p>;
};
