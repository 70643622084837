import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../../styles";
import { TextButton } from "../Buttons/TextButton";

export const HowToTop = ({ setPage }) => {
  const commonClasses = useCommonStyles();

  const { t } = useTranslation();

  return (
    <div className={commonClasses.top}>
      <h2 className={commonClasses.title}>{t("howToTopTitle")}</h2>
      <TextButton onClick={() => setPage("recording")}>
        {t("howToTopCancel")}
      </TextButton>
    </div>
  );
};
