import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../styles";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { TextButton } from "./Buttons/TextButton";

const useStyles = createUseStyles({
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "36px",
  },
});

export const ResultBreakDown = ({ setPageTitle }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { t } = useTranslation();

  const resultGroups = [
    {
      title: t("resultBreakdown1Title"),
      description: t("resultBreakdown1Text"),
    },
    {
      title: t("resultBreakdown2Title"),
      description: t("resultBreakdown2Text"),
    },
    {
      title: t("resultBreakdown3Title"),
      description: t("resultBreakdown3Text"),
    },
  ];
  return (
    <div>
      <div className={classes.top}>
        <button className={commonClasses.info}>
          <InfoIcon />
        </button>
        <h2>{t("resultBreakdownTitle")}</h2>
        <TextButton onClick={() => setPageTitle("result")}>
          {t("resultBreakdownClose")}
        </TextButton>
      </div>
      <div>
        {resultGroups.map(({ title, description }) => (
          <div key={title}>
            <b>{title}</b>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
