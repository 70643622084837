import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { ReactComponent as StarGreyIcon } from "../icons/star_grey.svg";
import { ReactComponent as StarYellowIcon } from "../icons/star_yellow.svg";
import { ReactComponent as StarRedIcon } from "../icons/star_red.svg";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
  },
});

export const SoundLevel = ({ fakeVolume, setPeak }) => {
  const classes = useStyles();

  const [volume, setVolume] = useState();

  useEffect(() => {
    let interval;

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        // Create an audio context and connect the stream source to an analyzer node
        const context = new AudioContext();
        const source = context.createMediaStreamSource(stream);
        const analyzer = context.createAnalyser();
        source.connect(analyzer);

        // The array we will put sound wave data in
        const array = new Uint8Array(analyzer.fftSize);

        function getPeakLevel() {
          analyzer.getByteTimeDomainData(array);
          return array.reduce(
            (max, current) => Math.max(max, Math.abs(current - 127)),
            0
          );
        }

        function tick() {
          const peak = getPeakLevel();
          setVolume(peak);

          setPeak && setPeak(peak);
        }

        interval = setInterval(() => {
          tick();
        }, 300);

        // Do whatever else with your stream...
      });
    return () => clearInterval(interval);
  }, [setPeak]);

  const targetVolume = fakeVolume || volume;

  return (
    <div className={classes.container}>
      {[0, 1, 2].map((number) => {
        if (targetVolume < 20) {
          return <StarGreyIcon key={number} />;
        } else if (targetVolume < 200) {
          return <StarYellowIcon key={number} />;
        } else {
          return <StarRedIcon key={number} />;
        }
      })}
    </div>
  );
};
