import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  btn: {
    width: "300px",
    height: "44px",
    padding: "8px 4px",
    backgroundColor: (props) => props.color || "#076459",
    borderRadius: "12px",
    color: "#FCFCFD",
    fontSize: "18px",
    fontWeight: "600",
    border: "none",
    fontFamily: "DM Sans, sans-serif",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: (props) => props.hoverColor || "#076459",
    },

    "&:disabled": {
      backgroundColor: "#EAECF0",
      color: "#667085",
    },

    "&:disabled:hover": {
      backgroundColor: "#EAECF0",
      color: "#667085",
    },
  },
});

export const Button = ({ children, color, hoverColor, ...rest }) => {
  const classes = useStyles({ color, hoverColor });

  return (
    <button className={classes.btn} {...rest}>
      {children}
    </button>
  );
};
