import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    tile: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      backgroundColor: "#BADED6",
      color: "#FFFFFF",
      padding: "8px",
      borderRadius: "8px",
      textAlign: "center",
      width: "100%",
      maxWidth: "300px",
    },
    text: {
      fontSize: "14px",
      margin: "8px 0",
      color: "#000",
    },
    discountCode: {
      fontSize: "20px",
      marginRight: "4px",
      color: "#000",
    },
    code: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#000",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    image: {
      marginRight: "8px",
    },
  });

  const DiscountTile = () => {
    const { t } = useTranslation();
    const classes = useStyles();
  
    return (
      <div className={classes.tile}>
        <div className={classes.row}>
        <img
          className={classes.image}
          src="/images/discount_percent.png"
          alt="voice wave"
          width={"60px"} 
        />
          <div className={classes.textAndCodeContainer}>
            <div className={classes.text}>{t("discountText")}</div>
            <div className={classes.row}>
              <div className={classes.discountCode}>{t("discountCode")}</div>
              <div className={classes.code}>{' VIVIDMIND15'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default DiscountTile;