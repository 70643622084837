import { useState } from "react";
import { useTranslation } from "react-i18next";

import { createUseStyles } from "react-jss";
import { useCommonStyles } from "../styles";
import { PagesSwitcher } from "./PagesSwitcher";

const useStyles = createUseStyles({
  container: {
    marginTop: "24px",
  },
  checkboxContainer: {
    margin: "20px 0",
  },
});

export const Rules = ({ setPage }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={commonClasses.top}>
        <h2 className={commonClasses.title}>{t("rulesTitle")}</h2>
      </div>
      <div>
        <p>{t("rules1")}</p>
        <p>{t("rules2")}</p>
        <p>{t("rules3")}</p>
      </div>
      <div className={classes.checkboxContainer}>
        <label htmlFor="accept-rules" className={commonClasses.label}>
          <input
            value={checked}
            onChange={() => setChecked((current) => !current)}
            type="checkbox"
            id="accept-rules"
            className={commonClasses.checkbox}
          />
          {t("rulesAccept")}
        </label>
      </div>
      <PagesSwitcher
        isForwardDisabled={!checked}
        previousPage="aboutTechnology"
        nextPage="howTo1"
        setPage={setPage}
      />
    </div>
  );
};
