import { useState } from "react";
import { createUseStyles } from "react-jss";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "./App.css";
import { StartPage } from "./pages/StartPage";
import { Footer } from "./components/Footer";
import { ContentContainer } from "./components/ContentContainer";
import en from "./locales/en.json";
import pl from "./locales/pl.json";

const queryParams = new URLSearchParams(window.location.search);
const lng = queryParams.get("lng");

i18n.use(initReactI18next).init({
  resources: {
    pl: {
      translation: pl,
    },
    en: {
      translation: en,
    },
  },
  lng: lng,
  fallbackLng: "pl",

  interpolation: {
    escapeValue: false,
  },
});

const queryClient = new QueryClient();

const useStyles = createUseStyles({
  frame: {
    width: "700px",
    height: "600px",
    fontFamily: "DM Sans, sans-serif",
    position: "relative",

    "@media screen and (max-width: 560px)": {
      width: "100%",
      height: "auto",
    },
  },
});

function App() {
  const [viewStart, setViewStart] = useState(true);

  const classes = useStyles(viewStart);

  return (
    <QueryClientProvider client={queryClient}>
      <div className={classes.frame}>
        {viewStart ? (
          <>
            <StartPage onButtonCLick={() => setViewStart(false)} />
            <Footer variant="white" />
          </>
        ) : (
          <ContentContainer setViewStart={setViewStart} />
        )}
      </div>
    </QueryClientProvider>
  );
}

export default App;
