import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { HowToContent } from "./Content";
import { PagesSwitcher } from "../PagesSwitcher";
import { HowToTop } from "./Top";
import { SoundLevel } from "../SoundLevel";

const useStyles = createUseStyles({
  volumeLevels: {
    display: "flex",
    flexDirection: "column",
  },
});

export const HowToThird = ({ setPage }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <HowToTop setPage={setPage} />
      <HowToContent title={t("howToThirdTitle")} text={t("howToThirdText")}>
        <div className={classes.volumeLevels}>
          <p style={{ color: "#98A2B3" }}>{t("howToThirdSoundLevel1")}</p>
          <SoundLevel fakeVolume={10} />
          <p style={{ color: "#04C57E" }}>{t("howToThirdSoundLevel2")}</p>
          <SoundLevel fakeVolume={190} />
          <p style={{ color: "#F20505" }}>{t("howToThirdSoundLevel3")}</p>
          <SoundLevel fakeVolume={500} />
        </div>
      </HowToContent>
      <PagesSwitcher
        previousPage="howTo2"
        nextPage="recording"
        setPage={setPage}
      />
    </>
  );
};
