import { createUseStyles } from "react-jss";
import { useCommonStyles } from "../styles";
import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../icons/chevron-right.svg";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

export const PagesSwitcher = ({
  previousPage,
  nextPage,
  isForwardDisabled,
  setPage,
  setViewStart,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const handleBackClick = () => {
    if (previousPage) {
      setPage(previousPage);
    } else {
      setViewStart(true);
    }
  };

  const handleForwardClick = () => {
    if (nextPage) {
      setPage(nextPage);
    }
  };

  return (
    <div className={classes.container}>
      <button className={commonClasses.arrowButton} onClick={handleBackClick}>
        <ChevronLeftIcon />
      </button>
      <button
        disabled={isForwardDisabled}
        className={commonClasses.rightArrowButton}
        onClick={handleForwardClick}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};
