import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../styles";
import { TextButton } from "./Buttons/TextButton";
import { PagesSwitcher } from "./PagesSwitcher";

const useStyles = createUseStyles({
  container: {
    marginTop: "24px",
  },
  content: {
    "& a": {
      color: "#076459",
      textDecoration: "none",
    },
  },
  partners: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "16px",
    margin: "32px 0",
    "& p": {
      fontSize: "14px",
      fontWeight: 700,
    },
  },
});

export const AboutTechnology = ({ setPage, setViewStart }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={commonClasses.top}>
        <h2 className={commonClasses.title}>{t("aboutTitle")}</h2>
        <TextButton>
          <a href="https://vividmind.health/" target="_blank" rel="noreferrer">
            {t("aboutLearnMore")}
          </a>
        </TextButton>
      </div>
      <div className={classes.content}>
        <p>{t("about1")}</p>
        <p>
          {t("about2")}{" "}
          <a
            href="https://patents.google.com/patent/WO2017017014A1/en"
            target="_blank"
            rel="noreferrer"
          >
            {t("aboutLink1")}
          </a>
          {t("about3")}{" "}
          <a
            href="https://patents.google.com/patent/WO2017017014A1/en"
            target="_blank"
            rel="noreferrer"
          >
            {t("aboutLink2")}
          </a>
          {t("about4")}
        </p>
      </div>
      <div className={classes.partners}>
        <p>{t("aboutPartners")}</p>
        <img src="/svg/univer.svg" alt="universitet wroclaw logo" />
        <img src="/svg/univer_pomor.svg" alt="universitet pomorski logo" />
      </div>
      <PagesSwitcher
        setPage={setPage}
        nextPage="rules"
        setViewStart={setViewStart}
      />
    </div>
  );
};
