import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { HowToContent } from "./Content";
import { PagesSwitcher } from "../PagesSwitcher";
import { HowToTop } from "./Top";

const useStyles = createUseStyles({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
});

export const HowToSecond = ({ setPage }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <HowToTop setPage={setPage} />
      <HowToContent title={t("howToSecondTitle")} text={t("howToSecondText")}>
        <img
          className={classes.image}
          src="/images/voice_wave.png"
          alt="voice wave"
        />
      </HowToContent>
      <PagesSwitcher
        previousPage="howTo1"
        nextPage="howTo3"
        setPage={setPage}
      />
    </>
  );
};
