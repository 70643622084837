import { useState, useEffect } from "react";
import { useCommonStyles } from "../../styles";

export const SixCountdown = () => {
  const commonStyles = useCommonStyles();
  const [currentNumber, setCurrentNumber] = useState(6);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNumber((curr) => curr - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <p className={commonStyles.countdownNumber}>{`0:0${currentNumber}`}</p>
  );
};
