import { createUseStyles } from "react-jss";

import { LanguageSwitcher } from "./LanguageSwitcher";

const useStyles = createUseStyles({
  header: {
    width: "700px",
    boxSizing: "border-box",
    height: "48px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 32px",
    // position: "fixed",
    // top: 0,
    // left: 0,
    zIndex: 100,

    "@media screen and (max-width: 560px)": {
      width: "100%",
      padding: "8px 16px",
    },

    "& #logo": {
      width: "180px",
      height: "32px",
      pointerEvents: "all",
    },
  },
});

export const Header = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <img src="/svg/logo_black.svg" alt="logo" id="logo" />
      <LanguageSwitcher />
    </header>
  );
};
