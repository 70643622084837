import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { ReactComponent as ErrorIcon } from "../icons/error.svg";
import { TextButton } from "./Buttons/TextButton";
import { Button } from "./Buttons/Button";

const useStyles = createUseStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80%",

    "@media screen and (max-width: 560px)": {
      flexDirection: "column",
      marginTop: "25%",
    },

    "& p": {
      fontSize: "24px",
      width: "60%",
      textAlign: "center",

      "@media screen and (max-width: 560px)": {
        order: 2,
        width: "100%",
      },
    },
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "@media screen and (max-width: 560px)": {
      flexDirection: "column",
      gap: "32px",
      marginTop: "132px",
    },
  },
  textButtonContainer: {
    "@media screen and (max-width: 560px)": {
      order: 2,
    },
  },
});

export const Error = ({ text, setPage, setViewStart }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <p>{text}</p>
        <ErrorIcon />
      </div>
      <div className={classes.bottom}>
        <div className={classes.textButtonContainer}>
          <TextButton onClick={() => setViewStart(true)}>
            {t("errorEnd")}
          </TextButton>
        </div>
        <Button onClick={() => setPage("recording")}>{t("errorAgain")}</Button>
      </div>
    </div>
  );
};
