import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../styles";
import { Button } from "../components/Buttons/Button";
import { LanguageSwitcher } from "../components/LanguageSwitcher";

const useStyles = createUseStyles({
  page: {
    width: "100%",
    height: "100%",
    backgroundImage: "url(/images/start_background.jpeg)",
    backgroundSize: "cover",

    "@media screen and (max-width: 560px)": {
      height: "800px",
      backgroundImage: "url(/images/start_background_mobile.png)",
    },
  },
  layer: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), rgba(127, 127, 127, 0.5)",
  },
  container: {
    padding: "64px 100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "48px",
    textAlign: "center",
    color: "#F9FAFB",

    "@media screen and (max-width: 560px)": {
      padding: "64px 26px",
    },
  },
  title: {
    fontSize: "36px",

    "@media screen and (max-width: 560px)": {
      fontSize: "24px",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: 400,

    "@media screen and (max-width: 560px)": {
      fontSize: "14px",
    },
  },
  link: {
    color: "#F9FAFB",
    fontWeight: 700,
  },
  checkboxContainer: {
    width: "24px",
    height: "24px",
    padding: "0",
    border: ({ checkboxHighlighted }) =>
      checkboxHighlighted && "1.5px solid red",
    borderRadius: "4px",
  },
  lngSwitcher: {
    position: "fixed",
    top: 0,
    left: 600,
  },
});

export const StartPage = ({ onButtonCLick }) => {
  const [checked, setChecked] = useState(false);
  const [checkboxHighlighted, setCheckboxHightlighted] = useState(false);

  const classes = useStyles({ checkboxHighlighted });

  const commonClasses = useCommonStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.page}>
      <div className={classes.layer}>
        <div className={classes.container}>
          <div className={classes.lngSwitcher}>
            <LanguageSwitcher />
          </div>
          <img src="/svg/logo.svg" alt="Logo" />
          <div>
            <h1 className={classes.title}>{t("homepageTitle")}</h1>
            <h4 className={classes.subtitle}>{t("homepageSubtitle")}</h4>
          </div>
          <label htmlFor="accept" className={commonClasses.label}>
            <div className={classes.checkboxContainer}>
              <input
                value={checked}
                onChange={() => {
                  setChecked((current) => !current);
                  setCheckboxHightlighted(false);
                }}
                type="checkbox"
                id="accept"
                className={commonClasses.checkbox}
              />
            </div>
            {t("homepageAccept")}{" "}
            <a
              href="https://vividmind.health/pl/regulamin"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {t("homepageRegulamin")}
            </a>{" "}
            {t("homepageAnd")}{" "}
            <a
              className={classes.link}
              href="https://vividmind.health/pl/polityka-prywatnosci"
              target="_blank"
              rel="noreferrer"
            >
              {t("homepagePrivacyPolicy")}
            </a>
          </label>
          <div
            onClick={(e) => {
              if (!checked) {
                setCheckboxHightlighted(true);
              }
            }}
          >
            <Button
              onClick={onButtonCLick}
              disabled={!checked}
              style={{ pointerEvents: `${checked ? "unset" : "none"}` }}
            >
              {t("homepageStart")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
