import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  lng: {
    fontSize: "16px",
  },
});

const langs = [
  {
    name: "PL",
    value: "pl",
    icon: "/svg/flags/pl.svg",
  },
  {
    name: "EN",
    value: "en",
    icon: "/svg/flags/en.svg",
  },
];

export const LanguageSwitcher = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const lngParam = queryParams.get("lng") || 'en';

  queryParams.set("lng", "en");
  console.log(queryParams.toString());

  const classes = useStyles();

  const handleClick = () => {
    if (lngParam === "pl") {
      queryParams.set("lng", "en");
    } else {
      queryParams.set("lng", "pl");
    }
    window.location.search = queryParams.toString();
  };

  const { icon, name } = langs.find(({ value }) => value === lngParam);

  return (
    <div className={classes.container} onClick={handleClick}>
      <img src={icon} alt="flag" />
      <p className={classes.lng}>{name}</p>
    </div>
  );
};
